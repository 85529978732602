<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">Agreement and terms</div>
      <pre class="content">
        Conditions relating to the use of the Service

The Service can be provided to a person with a full legal capacity under the legislation of a country or a territory where such person resides, which usually means a person is required to be sixteen (16) years old. It is possible for a person to use the Service below the age of legal capacity if the authorization from the person's parents or guardians is given. Such authorization may be provided in a form of written authorization of a parent/guarding submitted to 788skins.com or by implicative actions of a parent/guardian, such as executing a payment from a card, opened in the name of a minor.
No individual under the age of fourteen (14) may use the Service, regardless of any authorization from a parent/guardian to use the Service. If under legislation of a country or a territory, where an individual resides, it is required for such individual to be over this age to use the Service, the legislation of such country or territory shall apply.
You need a supported Web browser to access the Service. You acknowledge and agree that 788skins.com may cease to support a given Web browser and that your continuous use of the Service will require you to download a supported Web browser. You also acknowledge and agree that the performance of the Service is incumbent on the performance of your computer equipment and your Internet connection.
You agree to register and sign in for the Services through your Steam account provided by the Valve Corporation. You are solely responsible for managing your account and password and for keeping your password confidential. You are also solely responsible for restricting access to your account.
You agree that you are responsible for all activities that occur on your account or through the use of your password by yourself or by other persons. If you believe that a third party has access to your password, use the password regeneration feature of the Service to obtain a new password as soon as possible. In all circumstances, you agree not to permit any third party to use or access the Service.

As a condition to your use of the Service, you agree not to:
impersonate or misrepresent your affiliation with any person or entity;
access, tamper with, or use any non-public areas of the Service or 788skins.com's computer systems;
attempt to probe, scan, or test the vulnerability of the Service or any related system or network or breach any security or authentication measures used in connection with the Service and such systems and networks;
attempt to decipher, decompile, disassemble, reverse engineer or otherwise investigate any of the software or components used to provide the Service;
harm or threaten to harm other users in any way or interfere with, or attempt to interfere with, the access of any user, host or network, including without limitation, by sending a virus, overloading, flooding, spamming, or mail-bombing the Service;
provide payment information belonging to a third party;
use the Service in an abusive way contrary to its intended use, to 788skins.com policies and instructions and to any applicable law;
systematically retrieve data or other content from the Service to create or compile, directly or indirectly, in single or multiple downloads, a collection, compilation, database, directory or the like, whether by manual methods, through the use of bots, crawlers, or spiders, or otherwise;
make use of the Service in a manner contrary to the terms and conditions under which third parties provide facilities and technology necessary for the operation of the Service, such as G2A or Valve;
infringe third party intellectual property rights when using or accessing the Service, including but not limited to in making available virtual items by using the Service;
make use of, promote, link to or provide access to materials deemed by 788skins.com at its sole discretion to be offensive or cause harm to 788skins.com reputation, including, but not limited to, illegal content and pornographic content and content deemed offensive or injurious to 788skins.com and/or the Service (such as Warez sites, IRC bots and torrent sites).
In the event of discrepancies between the actual and intended drops, you shall not use such case until you receive notification of the elimination of such discrepancies. You also agree to notify us about such discrepancies in drop immediately after noticing it via support. Using the Services after discovering such discrepancies and/or without notification shall be deemed as the use of the Service in an abusive way contrary to its intended use.
The withdraw of the skins will be available after you refill your balance on the site up to the minimal amount $2 for one transaction
Due to the nature of the Services (the supply of digital content which is not supplied on a tangible medium) after you refill the balance you thereby loss your right of withdrawal from the Service, however such right of withdrawal and corresponding refunds may be kept if envisaged by the applicable legislation. Every request for withdrawal shall be addressed to our support team via support button.

Termination

We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
We will terminate or suspend access to our Service immediately, without prior notice or liability, for accounts impersonating our staff members (e.g. "administrator", "moderator").
Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.
All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
Using multiple accounts for any purposes on the Service is strictly forbidden and might lead to a termination of all accounts without notice.
We may temporarily restrict access to our Service of users who have used multiple bank cards to refill their balance for a quick security check through our Support team.
Using strong language or acting disrespectful and rude in a conversation with a support agent is forbidden and might lead to a termination without notice.

Links To Other websites

Our Service may contain links to third-party websites or services that are not owned or controlled by 788skins.com.
788skins.com has no control over and assumes no responsibility for the content, privacy policies, or practices of any third party websites or services. You further acknowledge and agree that 788skins.com will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.
We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.

No warranties

This website is provided "as is" without any representations or warranties, express or implied. 788skins.com makes no representations or warranties in relation to this website or the information and materials provided on this website. Without prejudice to the generality of the foregoing paragraph, 788skins.com does not warrant that: this website will be constantly available, or available at all; or the information on this website is complete, true, accurate or non-misleading. Nothing on this website constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any legal, financial or medical matter you should consult an appropriate professional.

Affiliation

We are in NO WAY affiliated with or endorsed by the Valve corporation, Counter Strike: Global Offensive, Steam or any other trademarks of the Valve corporation.

788skins.com account

By topping up an amount to your 788skins.com account, you accept that the credits received (labeled by the $-sign) are a limited use internal website credits to be spent exclusively on products and services presented on the website and are not worth real money and not subject to any refunds.

From time to time, we may add different events and/or additional services on our site with the specific credits with limited use for such events and/or additional services. Such credits may be given as an additional reward from your activities on our site, can be provided in different forms (currency, specific skins and/or characters) at our discretion, and shall not be considered as the 788skins.com Wallet credits. Giving you such credits shall not be deemed as the additional 788skins.com Wallet and we reserve the right to delete such credits and/or limit their use without any compensations.

Skins that have been sold automatically after not being withdrawn within 30 days are not subject to any refunds.

Avoid websites that are looking like they are owned or controlled by 788skins.com - we don't have any affiliate sites.

All of our contact details are listed on our site.
Everyone pretending to be "administrator", "moderator" or "trade bot" of 788skins.com is a scammer - we will never send you screenshots of our control panel or add you on Steam for purposes of trading your items.

Additional Terms and Conditions; EULAs

Referral codes

The unauthorized use of proprietary referral codes issued specifically to particular individuals (e.g. content creators, bloggers, media stars, professional e-sports players, the "Proprietary Referrals"), as well as any referral codes made up to resemble the Proprietary Referrals and mislead the audience or have domain names of CS:GO websites in them (the "Misleading Referrals") is forbidden. The infringers will not be allowed to benefit from or make us of any bonuses accruing from the use of such Proprietary Referrals.

Personal data

We can use payment emails to notify customers about news and discounts.
You are responsible for any fees, taxes or other costs associated with the purchase and delivery of your items resulting from charges imposed by your relationship with payment services providers or the duties and taxes imposed by your local customs officials or other regulatory body.
For customer service inquiries or disputes, You may contact us by email at support@788skins.com. (email of submerchant)
Where possible, we will work with you and/or any user selling on our website, to resolve any disputes arising from your purchase.

Daily Free Bonus

We can provide our users with the 788skins Daily Free bonus, subject to the terms and conditions at our discretion, that inter alia may limit the possibility to use Daily Free Bonus for certain users. A user may be limited in using Daily Free Bonus if certain activity or non-abuse conditions are not met, as well as in any other situation, that we may consider as an abusive or non-compliant with these Terms.

Daily Free Bonus is strictly limited to one per user and is intended for recreational purposes only. Accordingly, any activity which unfairly exploits or manipulates Daily Free Bonus is strictly forbidden.

In case a user violates any of the above conditions, or is found to be exploiting Daily Free Bonus, 788skins may, in its sole discretion: (i) limit the eligibility of such user to participate in the Daily Free Bonus or use the Service; (ii) amend the terms of Daily Free Bonus; (iii) cancel and forfeit that users Daily Free Bonus eligibility or Daily Free Bonus balance at any time.

All balance (or other benefits) received under Daily Free Bonus will be erased from the user's account after 365 days of inactivity. In the event of dispute, 788skins's decision is final and not subject to appeal.

788skins may cancel, amend or modify Daily Free Bonus by publishing the amended or modified terms or notice of cancellation. Such amendment, modification or cancellation shall be with immediate effect.


Intellectual property

All of the content ("Content") on our site - including text, software, scripts, code, designs, graphics, photos, sounds, music, videos, applications, interactive features, articles news stones, sketches animations, stickers, general artwork and other content– is property of ours or others we license or otherwise legally access this Content from, and is protected by copyright, trademark, patent and other laws.

We reserve all rights to such Content, including but not limited to, all copyrights, trademarks, service marks, and trade names are owned, registered and/or licensed by us. You do not acquire a license or any ownership rights to any copyright, trademarks, service marks or trade names through your access or use of our site or Content.


Giveaways

From time to time we may organize giveaways, either personally or via third parties, according to terms and conditions announced with regard to such giveaways respectively. We may amend the terms and conditions, as well as to cancel any giveaway, if necessary at our sole discretion.

In case such giveaway is organized by a third party, notwithstanding a possible use of our Site therein, we shall not be liable for inappropriate organization thereof.

In no event we shall be liable for your possibility to obtain the corresponding result, including but not limited to events if the incorrect information about an awarded user and/or delivery address is provided. Nor shall we be liable for applicable taxes and duties, which may arise from such giveaway and/or its delivery to an awarded user.

We shall have a right to withhold the delivery of giveaway until the necessary information is provided, as well as we shall have a right to cancel a giveaway to a specific user if such user and/or their actions do not comply with the applicable rules, including, but not limited to presence in a sanctions list, fraud actions, provision of wrongful data etc.

By participating in any giveaway, whether organized by us or by third party at our request, you agree to reconfirm the data you provided to us upon our request. Such data may include your name, surname, middle name/patronym, email, address of residence and delivery or any other data, which may be necessary for us to accomplish the giveaway and to deliver its results.

Upon receipt of the giveaway result you also agree to provide us with the photo of yours with the giveaway result.

You consent us to use the data you provided hereinabove for assurance of giveaway result were received by users, as well as for the marketing of the Site and/or giveaways.

We shall keep the right to cancel the delivery of giveaway if such provisions are not observed by you.

      </pre>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>

pre { // 兼容多个浏览器
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    *word-wrap: break-word;
    *white-space : normal ;
    font-size: 18px;
    color: #848492;
    padding: 16px 0;
}
.doubt {
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
    padding: 32px;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .title1 {
    font-size: 20px;
    color: #c3c3e2;
    padding: 16px 0;
  }
  .con {
    font-size: 18px;
    color: #848492;
    span {
      margin-top: 10px;
      display: inline-block;
    }
  }
}
</style>