export default {
  common: {
    username: 'username',
    password: 'password',
    save: 'Save1',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    forever: 'Forever',
    expired: 'Expired',
    register: 'Register',
    login: 'Login',
    请先登录: 'Please login first',
    手机注册: 'Mobile registration',
    手机号码: 'Mobile number',
    设置密码: 'Set password',
    验证码: 'Verification code',
    获取验证码: 'Get verification code',
    登录: 'Login',
    手机号码或邮箱: 'Mobile number or email',
    密码: 'Password',
    记住登录: 'Remember login',
    忘记密码: 'Forget password',
    还没账号: 'No account yet',
    点击注册: 'Click to register',
    重置密码: 'Reset password',
    个人中心: 'Personal center',
    我的账单: 'My bill',
    消息通知: 'Message notification',
    退出登录: 'Sign out',
    编辑: 'Edit',
    选择当前页: 'Select current page',
    取消选择: 'Cancel selection',
    兑换: 'EXCH',
    充值: 'Recharge',
    关于我们: 'About us',
    用户协议: 'User agreement',
    隐私条款: 'Privacy terms',
    协议与条款: 'Agreement and terms',
    常见问题: 'Common problem',
    暂无数据: 'No data',
    服务不满意: 'Service is not satisfied, please contact customer service',
    等待中: 'Waiting',
    进行中: 'In progress',
    确定: 'Confirm',
    全部: 'All',
    筛选: 'Screening',
    取消全部: 'Cancel all',
    取消: 'Cancel',
    保存: 'Save',
    绑定: 'Bind',
    修改密码: 'Change password',
    偏好设置: 'Preference settings',
    邮箱注册: 'Email registration',
    邮箱: 'Email',
    邀请码: 'Invitation code',
    注册: 'Register',
    
    
  },
  biz:{
    盲盒大厅: 'Blind Box Hall',
    盲盒对战: 'Blind Box Battle',
    ROLL房: 'ROLL Room',
    幸运饰品 : 'Lucky Accessories',
    饰品商城 : 'Mall',
    活动推广 : 'Promotion',
    饰品背包 : 'Backpack',
    加群有奖 : 'Join Group Award',
    暂时没有红包哦 : 'No red envelopes yet',
    已结束: 'Ended',
    回合: 'Round',
    查看: 'View',
    查看更多: 'View more',
    时间: 'Time',
    编号: 'Number',
    价格从低到高: 'Price from low to high',
    价格从高到低: 'Price from high to low',
    充值流水: 'Recharge flow',
    余额流水: 'Balance flow',
    日期: 'Date',
    支付: 'Payment',
    充值方式: 'Recharge method',
    充值金额: 'Recharge amount',
    充值结果: 'Recharge result',
    购买物品: 'Purchase items',
    类型: 'Type',
    描述: 'Description',
    数量: 'Quantity',
    余额: 'Balance',
    包含以下皮肤: 'Contains the following skins',
    最近掉落: 'Recent drop',
    挑战: 'Challenge',
    排行榜: 'Leaderboard',
    对战之神: 'God of War',
    对战规则: 'Battle rules',
    对战记录: 'Battle record',
    创建对战: 'Create a battle',
    个房间等待您加入: ' rooms are waiting for you to join',
    每局活动: 'In each round of activities, the player who continuously opens the same blind box has the highest total prize value and can get all the prizes',
    我的对战记录: 'My battle record',
    所有记录: 'All records',
    玩家: 'Player',
    盲盒信息: 'Blind box information',
    状态: 'Status',
    精彩回顾: 'Wonderful review',
    对战结果: 'Battle result',
    全部房间: 'All rooms',
    我参与的: 'I participated in',
    官方: 'Official',
    主播: 'Anchor',
    立即参与: 'Join now',
    等待玩家: 'Waiting for players',
    对战历史: 'Battle history',
    参加对战编号: 'Join battle number',
    详情: 'Details',
    打开: 'Open',
    包含以下奖励: 'Contains the following rewards',
    胜利: 'Victory',
    失败: 'Failure',
    库存: 'Inventory',
    立即购买: 'Buy now',
    奖池: 'Prize pool',
    件数: 'Number of pieces',
    人数: 'Number of people',
    加入房间: 'Join room',
    开奖时间: 'Draw time',
    参与人数: 'Number of participants',
    房间简介: 'Room introduction',
    免费皮肤: 'Free skin',
    房间编号: 'Room number',
    截止时间: 'Deadline',
    中奖纪录: 'Winning record',
    奖池奖品: 'Prize pool prizes',
    价值: 'Value',
    件: 'Pieces',
    剩余: 'Remaining',
    已送出: 'Sent out',
    共计: 'Total',
    人: 'People',
    最低金额: 'Minimum amount',
    最高金额: 'Maximum amount',
    按名称搜索: 'Search by name',
    饰品: 'Accessories',
    取回助手: 'Retrieve assistant',
    没有进行中的报价: 'No ongoing offers',
    全部隐藏: 'All hidden',
    选择你心仪的商品: 'Choose your favorite product',
    您的可用金币数量为: 'Your available gold coin quantity is',
    选择你要取回的饰品: 'Choose the accessories you want to retrieve',
    //点击取回后，饰品稍后会在取回助手中，请点击右侧取回助手点击接受报价。12小时内累计拒绝报价或不收货行为超过 2 次，你的取回功能将会被系统禁用 24 小时。
    点击取回后: 'After clicking retrieve, the accessories will be in the retrieve assistant later, please click the retrieve assistant on the right to accept the offer. If you refuse the offer or do not receive the offer more than 2 times within 12 hours, your retrieve function will be disabled by the system for 24 hours.',
    邀请: 'Invite',
    用户ID: 'User ID',
    上次登录: 'Last login',
    Steam交易链接: 'Steam trading link',
    输入您的交易链接: 'Enter your trading link',
    新用户3天内可以绑定上级: 'New users can bind their superiors within 3 days',
    账号管理: 'Account management',
    邮箱绑定: 'Email binding',
    开启声音: 'Turn on sound',
    关闭声音: 'Turn off sound',
    //活动结果提醒（参与活动结束后结果会通过浏览器通知告知）
    活动结果提醒: 'Activity result reminder (the result will be notified through the browser after the end of the activity)',
    点击这里: 'Click here',
    点击看教程: 'Click to see the tutorial',
    获取你的Steam交易链接: 'Get your Steam trading link',
    打不开Steam怎么办: 'What if Steam can’t be opened',
    取回通知: 'Retrieve notification',
    系统通知: 'System notification',
    姓名: 'Name',
    地址: 'Address',
    没有可以不填: 'No can not fill',
    本人已年满18周岁: 'I am over 18 years old',
    用户协议: 'User agreement',
    隐私条款: 'Privacy clause',
    和: 'And',
    我已经阅读并接受本站的: 'I have read and accepted',
    //已有账号?点击登录
    已有账号: 'Already have an account? Click to log in',
    想起密码了: 'Remember the password',
    规则: 'Rule',
    关于对战: 'About the battle',
    请输入对战场次编号: 'Please enter the battle number',
    验证对战: 'Verify the battle',
    取回: 'RETRV',
    取回中: 'Retrieving',
    开箱规则: 'Open box rules',
    盲盒开箱: 'Blind box opening',
    收入: 'Income',
    支出: 'Expenditure',
    充值: 'Recharge',
    皮肤兑换Z币: 'Skin exchange Z coin',
    对战失败: 'Battle failed',
    对战存在多个平局赢家平分输家的钱: 'There are multiple tie winners in the battle, and the losers share the money',
    购买盲盒: 'Buy blind box',
    加入对战房间: 'Join the battle room',
    购买幸运饰品: 'Buy lucky accessories',
    商城购买饰品: 'Buy accessories in the mall',
    我的购物车: 'My shopping cart',
    崭新出厂: 'Brand new factory',
    略有磨损: 'Slightly worn',
    久经沙场: 'Battle tested',
    破损不堪: 'Broken',
    战痕累累: 'Battle scarred',
    无涂装: 'No paint',
    放入背包: 'Put in backpack',
    掉落概率: 'Drop probability',
    跳过动画: 'Skip animation',
    选择你期望的幸运值: 'Choose your expected luck value',
    玩法介绍和特别说明: 'Game introduction and special instructions',
    随机物品: 'Random items',
    成功: 'Success',
    失败: 'Failure',
    试试运气: 'Try your luck',
    花费: 'Cost',
    你会有: 'You will have',
    //的机会，获得一件珍稀的饰品
    的机会: 'Chance to get a rare accessory',
    未支付: 'Unpaid',
    待支付: 'To be paid',
    支付成功: 'Payment success',
    支付失败: 'Payment failed',
    支付确认中: 'pending',
    名字: 'Last Name',
    姓氏: 'First Name',
    国家: 'Country',
    不能未空: 'Can not be empty',
    地区: 'Region',
    城市: 'City',
    地址: 'Address',
    邮编: 'Zip Code',
    邮箱: 'Email',
    电话: 'Phone',
    性别: 'Gender',
    身份证号: 'ID Number',
    卡号: 'Card Number',
    有效年: 'Valid Year',
    有效月: 'Valid Month',
    立即充值: 'Recharge now',
    取回错误请联系客服: 'Retrieve error, please contact customer service',
    正在取回中稍后重试: 'Retrieving, please try again later',
    请选择箱子: 'Please select a box',
    盲盒数量已达上限: 'The number of blind boxes has reached the upper limit',
    复制成功: 'Copy successfully',
    输入口令领红包: 'Enter the password to receive the red envelope',
    恭喜您获得: 'Congratulations on getting',
  },
  msg:{
    PF0001: 'Parameter error',
    PF0002: 'Please enter the red envelope password',
    PF0003: 'Red envelope information does not exist',
    PF0004: 'The password is wrong',
    PF0005: 'The red envelope has been grabbed',
    PF0006: 'The red envelope has expired',
    PF0007: 'The red envelope is empty',
    PF0008: 'Red envelope information error',
    PF0009: 'Congratulations on getting the red envelope',
    PF0010: 'No data',
    PF0011: 'Please select a box',
    PF0012: 'Create successfully',
    PF0013: 'Lack of battle id information',
    PF0014: 'Lack of player information in battle',
    PF0015: 'Game room information does not exist',
    PF0016: 'You are already in this room and cannot be paired with yourself',
    PF0017: 'The room is full and cannot be paired',
    PF0018: 'Blind box data does not exist',
    //对战失败	
    PF0019: 'Battle failed',
    //加入对战失败
    PF0020: 'Failed to join the battle',
    //加入成功",
    PF0021: 'Join successfully',
    //对战已结束",
    PF0022: 'The battle is over',
    //奖品库存不足，开奖失败",
    PF0023: 'The prize is out of stock and the lottery failed',
    //本场开奖已完成",
    PF0024: 'The lottery has been completed',
    //盲盒信息错误",
    PF0025: 'Blind box information error',
    //玩家信息错误",
    PF0026: 'Player information error',
    //参与失败，请充值",
    PF0027: 'Participation failed, please recharge',
    //失败",
    PF0028: 'Failed',
    //盒子信息错误",
    PF0029: 'Box information error',
    //盲盒信息错误",
    PF0030: 'Blind box information error',
    //当天仅限开箱一次",
    PF0031: 'Only one box can be opened per day',
    //新注册用户仅限开箱一次",
    PF0032: 'Only one box can be opened for new registered users',
    //玩家信息不存在",
    PF0033: 'Player information does not exist',
    //当前余额不足，请充值",
    PF0034: 'The current balance is insufficient, please recharge',
    //缺少玩家信息",
    PF0035: 'Lack of player information',
    //活动已结束",
    PF0036: 'The event is over',
    //报名时间已结束",
    PF0037: 'The registration time is over',
    //您已在该房间内，看看别的活动房间吧",
    PF0038: 'You are already in this room, take a look at other activity rooms',
    //密码或口令有误，参加失败",
    PF0039: 'The password or password is wrong, the participation failed',
    //未达到充值额度条件，参加失败",
    PF0040: 'Failed to meet the recharge quota',
    //口令错误或者充值金额未达标，参加失败",
    PF0041: 'The password is wrong or the recharge amount is not up to standard, the participation failed',
    //您不是该主播的下级或充值金额未达标，参加失败	",
    PF0042: 'You are not the subordinate of the anchor or the recharge amount is not up to standard, the participation failed',
    //房间已开奖",
    PF0043: 'The room has been awarded',
    //开奖时间还未到",
    PF0044: 'The lottery time has not arrived',
    //暂无抽奖玩家",
    PF0045: 'No lottery players',
    //暂无奖品，无法开奖",
    PF0046: 'No prizes, unable to draw',
    //抽奖发生错误",
    PF0047: 'Lottery error',
    //抽奖完成",
    PF0048: 'Lottery completed',
    //结算完成",
    PF0049: 'Settlement completed',
    //请输入账号",
    PF0050: 'Please enter your account number',
    //请输入密码",
    PF0051: 'Please enter your password',
    //账号或者密码有误",
    PF0052: 'The account or password is wrong',
    //登录成功",
    PF0053: 'Login successful',
    //请输入手机号码",
    PF0054: 'Please enter your mobile number',
    //手机号格式不正确",
    PF0055: 'The mobile phone number format is incorrect',
    //请输入验证码",
    PF0056: 'Please enter verification code',
    //请输入密码",
    PF0057: 'Please enter your password',
    //验证码错误",
    PF0058: 'Verification code error',
    //验证码已失效",
    PF0059: 'Verification code has expired',
    //新密码与旧密码相同",
    PF0060: 'The new password is the same as the old password',
    //操作成功",
    PF0061: 'Successful operation',
    //账号不存在",
    PF0062: 'Account does not exist',
    //未中奖",
    PF0063: 'No prize',
    //缺少饰品信息",
    PF0064: 'Lack of jewelry information',
    //缺少玩家信息",
    PF0065: 'Lack of player information',
    //开奖信息有误",
    PF0066: 'Lottery information error',
    //饰品信息不存在",
    PF0067: 'Jewelry information does not exist',
    //账户余额不足",
    PF0068: 'Insufficient account balance',
    //操作失败",
    PF0069: 'Operation failed',
    //恭喜中奖",
    PF0070: 'Congratulations on winning',
    //不支持该玩法",
    PF0071: 'This gameplay is not supported',
    //用户已存在",
    PF0072: 'User already exists',
    //手机号已注册",
    PF0073: 'Mobile phone number has been registered',
    //邀请码错误",
    PF0074: 'Invitation code error',
    //验证码已失效",
    PF0075: 'Verification code has expired',
    //注册失败",
    PF0076: 'Registration failed',
    //金额有误",
    PF0077: 'The amount is wrong',
    //用户信息有误",
    PF0078: 'User information error',
    //充值发生错误，请稍后再试	",
    PF0079: 'Recharge error, please try again later',
    //商户信息错误，请联系客服!",
    PF0080: 'Merchant information error, please contact customer service!',
    //购买商品出错，请稍后再试",
    PF0081: 'Purchase goods error, please try again later',
    //商品库存不足",
    PF0082: 'Insufficient inventory of goods',
    //购买成功",
    PF0083: 'Purchase success',
    //非测试账号",
    PF0084: 'Non-test account',
    //查无此用户",
    PF0085: 'No such user',
    //请输入您的交易链接",
    PF0086: 'Please enter your transaction link',
    //不可绑定",
    PF0087: 'Not bound',
    //绑定错误",
    PF0088: 'Binding error',
    //您已绑定推荐人",
    PF0089: 'You have bound the recommender',
    //已超过3日有效期，无法绑定",
    PF0090: 'It has exceeded the 3-day validity period and cannot be bound',
    //请输入您的昵称",
    PF0100: 'Please enter your nickname',
    //文件不存在",
    PF0101: 'File does not exist',
    //错误，稍后再试",
    PF0102: 'Error, try again later',
    //该手机号不存在",
    PF0103: 'The mobile phone number does not exist',
    //操作频繁",
    PF0104: 'Frequent operation',
    //皮肤取回中，请勿重复操作	",
    PF0105: 'Skin retrieval, please do not repeat the operation',
    //皮肤信息错误",
    PF0106: 'Skin information error',
    //皮肤不存在",
    PF0107: 'Skin does not exist',
    //玩家不存在",
    PF0108: 'Player does not exist',
    //取回成功",
    PF0109: 'Successfully retrieved',
    //账户未绑定Steam",
    PF0110: 'Account is not bound to Steam',
    //发送报价中,请关注您的取回助手",
    PF0111: 'Sending offer, please pay attention to your retrieval assistant',
    //操作成功，请等待发货并关注您的取回助手",
    PF0112: 'Operation successful, please wait for delivery and pay attention to your retrieval assistant',
    //饰品兑换中...请稍后再操作",
    PF0113: 'Jewelry exchange... Please operate later',
    //缺少皮肤信息",
    PF0114: 'Lack of skin information',
    //兑换失败",
    PF0115: 'Exchange failed',
    //兑换成功",
    PF0116: 'Exchange success',
    //邮箱格式错误",
    PF0117: 'Mailbox format error',
    //服务错误，请联系管理员
    "0": "Service error, please contact the administrator",
    
  }
}
