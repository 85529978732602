export default {
    common: {
      username: '用户名',
      password: '密码',
      save: '保存',
      edit: '编辑',
      update: '更新',
      delete: '删除',
      forever: '永久',
      expired: '过期',
      register: 'Регистрация',
      login: 'Регистрация',
      请先登录: 'Пожалуйста, войдите.',
      手机注册: 'Регистрация мобильного телефона',
      手机号码: 'Номер мобильного телефона',
      设置密码: 'Установить пароль',
      验证码: 'Проверочный код',
      获取验证码: 'Получить проверочный код',
      登录: 'Регистрация',
      手机号码或邮箱: 'Номер мобильного телефона или электронная почта',
      密码: 'Пароль',
      记住登录: 'Запомнить вход',
      忘记密码: 'Забыли пароль',
      还没账号: 'Еще нет аккаунта',
      点击注册: 'Нажмите, чтобы зарегистрироваться',
      重置密码: 'Сброс пароля',
      个人中心: 'Личный кабинет',
      我的账单: 'Мой счет',
      消息通知: 'Уведомление',
      退出登录: 'Выйти из системы',
      编辑: 'Редактировать',
      选择当前页: 'Выберите текущую страницу',
      取消选择: 'Отменить выбор',
      兑换: 'Обмен',
      充值: 'Пополнить',
      关于我们: 'О нас',
      用户协议: 'Пользовательское соглашение',
      隐私条款: 'Политика конфиденциальности',
      协议与条款: 'Соглашение и условия',
      常见问题: 'Часто задаваемые вопросы',
      暂无数据: 'Нет данных',
      服务不满意: 'Если вы не удовлетворены обслуживанием, свяжитесь с обслуживающим персоналом',
      等待中: 'Ожидание',
      进行中: 'В процессе',
      确定: 'Подтвердить',
      全部: 'Все',
      筛选:"Фильтр",
      取消全部: 'Отменить все',
      取消: 'Отменить',
      保存: 'Сохранить',
      绑定: 'Привязать',
      修改密码: 'Изменить пароль',
      偏好设置: 'Настройки предпочтений',
      邮箱注册: 'Регистрация по электронной почте',
      邮箱: 'Электронная почта',
      邀请码: 'Пригласительный код',
      
  
    },
    biz:{
      盲盒大厅:"Зал слепой коробки",
      盲盒对战: 'Слепая коробка',
      ROLL房:"ROLL комната",
      幸运饰品:"украшения",
      饰品商城:"магазин",
      活动推广:"активов",
      饰品背包:"рюкзак",
      加群有奖:"Приз за добавление группы",
      暂时没有红包哦:"В настоящее время нет красных конвертов.",
      已结束:"Завершено",
      回合:"Раунд",
      查看:"Посмотреть",
      查看更多:"Посмотреть больше",
      时间:"Время",
      编号:"Номер",
      价格从低到高:"Цена от низкой до высокой",
      价格从高到低:"Цена от высокой до низкой",
      充值流水:"Пополнить поток",
      余额流水:"Остаток потока",
      日期:"Дата",
      支付:"Оплата",
      充值方式:"Способ пополнения",
      充值金额:"Сумма пополнения",
      充值结果:"Результат пополнения",
      购买物品:"Покупка товара",
      类型:"Тип",
      描述:"Описание",
      数量:"Количество",
      余额:"Остаток",
      包含以下皮肤:"Содержит следующие скины",
      最近掉落:"Последний падение",
      挑战:"Вызов",
      排行榜:"Рейтинг",
      对战之神:"Бог сражений",
      对战规则:"Правила сражения",
      对战记录:"Запись сражения",
      创建对战:"Создать сражение",
      个房间等待您加入:"Комната ожидания 1 человека",
      每局活动:"В каждой игре игрок, который открывает одну и ту же слепую коробку, может получить все призы с наивысшей общей стоимостью.",
      我的对战记录:"Моя запись сражения",
      所有记录:"Все записи",
      玩家:"Игрок",
      盲盒信息:"Информация о слепой коробке",
      状态:"Состояние",
      精彩回顾:"Великолепный обзор",
      对战结果:"Результат сражения",
      全部房间:"Все комнаты",
      我参与的:"Я принимаю участие",
      官方:"Официальный",
      主播:"Аниматор",
      立即参与:"Принять участие",
      等待玩家:"Ожидание игрока",
      对战历史:"История сражения",
      参加对战编号:"Принять участие в номере сражения",
      详情:"Детали",
      打开:"Открыть",
      包含以下奖励:"Содержит следующие награды",
      胜利:"Победа",
      失败:"Поражение",
      库存:"Остаток",
      立即购买:"Купить сейчас",
      奖池:"Пул",
      件数:"Количество",
      人数:"Количество людей",
      加入房间:"Присоединиться к комнате",
      开奖时间:"Время розыгрыша",
      参与人数:"Количество участников",
      房间简介:"Описание комнаты",
      免费皮肤:"Бесплатные скины",
      房间编号:"Номер комнаты",
      截止时间:"Время окончания",
      中奖纪录:"Запись о выигрыше",
      奖池奖品:"Призовой фонд",
      价值:"Стоимость",
      件:"Шт.",
      剩余:"Остаток",
      已送出:"Отправлено",
      共计:"Всего",
      人:"Человек",
      最低金额:"Минимальная сумма",
      最高金额:"Максимальная сумма",
      按名称搜索:"Поиск по имени",
      饰品:"Украшения",
      取回助手:"Помощник по возврату",
      没有进行中的报价:"Нет текущих предложений",
      全部隐藏:"Скрыть все",
      选择你心仪的商品:"Выберите товар, который вам нравится",
      您的可用金币数量为:"Ваше количество доступных монет",
      选择你要取回的饰品:"Выберите украшение, которое вы хотите вернуть",
      //点击取回后，饰品稍后会在取回助手中，请点击右侧取回助手点击接受报价。12小时内累计拒绝报价或不收货行为超过 2 次，你的取回功能将会被系统禁用 24 小时。
      点击取回后:"После нажатия на кнопку возврата украшение будет возвращено в помощник по возврату. Нажмите кнопку возврата справа. Если в течение 12 часов отказываются от предложения или отказываются от предложения более 2 раз, ваша функция возврата будет отключена система на 24 часа.",
      邀请:"Приглашение",
      用户ID:"ID пользователя",
      上次登录:"Последний вход",
      Steam交易链接:"Ссылка на торговлю Steam",
      输入您的交易链接:"Введите свою ссылку на торговлю",
      新用户3天内可以绑定上级:"Новые пользователи могут привязать своего супериора в течение 3 дней",
      账号管理:"Управление аккаунтом",
      邮箱绑定:"Привязка почты",
      开启声音:"Включить звук",
      关闭声音:"Отключить звук",
      //活动结果提醒（参与活动结束后结果会通过浏览器通知告知）
      活动结果提醒:"Напоминание о результатах деятельности (результаты будут сообщены через браузер после окончания деятельности)",
      点击这里:"Нажмите здесь",
      点击看教程:"Нажмите, чтобы посмотреть учебник",
      获取你的Steam交易链接:"Получите свою ссылку на торговлю Steam",
      打不开Steam怎么办:"Что делать, если Steam не открывается",
      取回通知:"Уведомление о возврате",
      系统通知:"Системное уведомление",
      姓名:"Имя",
      地址:"Адрес",
      没有可以不填:"Можно не заполнять",
      本人已年满18周岁:"Я достиг совершеннолетия",
      用户协议:"Пользовательское соглашение",
      隐私条款:"Политика конфиденциальности",
      和:"и",
      我已经阅读并接受本站的:"Я прочитал и принял",
      //已有账号?点击登录
      已有账号:"Уже есть аккаунт?",
      想起密码了:"Вспомнил пароль?",
      注册:"Регистрация",
      规则: 'Правила',
      关于对战: 'О сражении',
      请输入对战场次编号: 'Введите номер сражения',
      验证对战: 'Проверка сражения',
      取回: 'Возврат',
      取回中: 'Возврат',
      开箱规则: 'Правила открытия',
      盲盒开箱: 'Открытие коробки',
      收入: 'Доход',
      支出: 'Расход',
      充值: 'Пополнение',
      皮肤兑换Z币: 'Обмен скинов на Z-монеты',
      对战失败: 'Поражение',
      对战存在多个平局赢家平分输家的钱: 'В сражении есть несколько победителей, которые делят деньги проигравшего',
      购买盲盒: 'Покупка коробки',
      加入对战房间: 'Присоединиться к комнате сражения',
      购买幸运饰品: 'Покупка удачных украшений',
      商城购买饰品: 'Покупка украшений в магазине',
      我的购物车: 'Моя корзина',
      崭新出厂: 'Новый',
      略有磨损: 'Немного потертый',
      久经沙场: 'Боевой след',
      破损不堪: 'Поврежденный',
      战痕累累: 'Боевые царапины',
      无涂装: 'Без покрытия',
      放入背包: 'Положить в рюкзак',
      掉落概率: 'Вероятность выпадения',
      跳过动画: 'Пропустить анимацию',
      选择你期望的幸运值: 'Выберите желаемое значение удачи',
      玩法介绍和特别说明: 'Правила и особые указания',
      随机物品: 'Случайный предмет',
      成功: 'Успешно',
      失败: 'Поражение',
      试试运气: 'Попробуйте удачу',
      花费: 'Расходы',
      你会有: 'У вас будет',
      //的机会，获得一件珍稀的饰品
      的机会: 'шанс получить редкий аксессуар',
      未支付: 'Не оплачено',
      待支付: 'Ожидание оплаты',
      支付成功: 'Оплата прошла успешно',
      支付失败: 'Ошибка оплаты',
      支付确认中: 'Подтверждение оплаты',
      名字: 'Имя',
      姓氏: 'Фамилия',
      国家: 'Страна',
      不能未空: 'Не может быть пустым',
      地区: 'Регион',
      城市: 'Город',
      地址: 'Адрес',
      邮编: 'Почтовый индекс',
      邮箱: 'Электронная почта',
      电话: 'Телефон',
      性别: 'Пол',
      身份证号: 'Номер паспорта',
      卡号: 'Номер карты',
      有效年: 'Год действия',
      有效月: 'Месяц действия',
      立即充值: 'Пополнить сейчас',
      取回错误请联系客服: 'Ошибка возврата, пожалуйста, свяжитесь с обслуживанием клиентов',
      正在取回中稍后重试: 'Возврат в процессе, повторите попытку позже',
      请选择箱子: 'Выберите коробку',
      盲盒数量已达上限: 'Количество коробок достигло предела',
      复制成功: 'Копирование успешно',
      输入口令领红包: 'Введите пароль, чтобы получить красный конверт',
      恭喜您获得: 'Поздравляем, вы получили',
    },
    msg:{
    //参数错误",
    PF0001:"Ошибка параметра",
    //请输入红包口令",
    PF0002:"Введите пароль красного конверта",
    //红包信息不存在",
    PF0003:"Информация о красном конверте не существует",
    //口令有误",
    PF0004:"Неверный пароль",
    //红包已抢过了",
    PF0005:"Красный конверт уже был схвачен",
    //红包已过期",
    PF0006:"Красный конверт истек",
    //红包抢光了",
    PF0007:"Красный конверт исчез",
    //红包信息错误",
    PF0008:"Ошибка информации о красном конверте",
    //恭喜抢到红包",
    PF0009:"Поздравляем с захватом красного конверта",
    //无数据",
    PF0010:"Нет данных",
    //请选择盒子",
    PF0011:"Выберите коробку",
    //创建成功",
    PF0012:"Создано успешно",
    //缺少对战id信息",
    PF0013:"Отсутствует информация о сражении",
    //缺少入战玩家信息",
    PF0014:"Отсутствует информация о входящем игроке",
    //游戏房间信息不存在",
    PF0015:"Информация об игровой комнате не существует",
    //您已经在该房间，不可与自己对战",
    PF0016:"Вы уже в этой комнате, не можете сражаться с собой",
    //房间人数已满",
    PF0017:"Комната заполнена",
    //盲盒数据不存在",
    PF0018:"Информация о слепой коробке не существует",
    //对战失败",
    PF0019:"Поражение",
    //加入对战失败",
    PF0020:"Не удалось присоединиться к сражению",
    //加入成功",
    PF0021:"Успешное присоединение",
    //对战已结束",
    PF0022:"Сражение окончено",
    //奖品库存不足，开奖失败",
    PF0023:"Недостаточно призового фонда, розыгрыш не удался",
    //本场开奖已完成",
    PF0024:"Розыгрыш завершен",
    //盲盒信息错误",
    PF0025:"Информация о слепой коробке неверна",
    //玩家信息错误",
    PF0026:"Информация об игроке неверна",
    //参与失败，请充值",
    PF0027:"Не удалось принять участие, пожалуйста, пополните счет",
    //失败",
    PF0028:"Поражение",
    //盒子信息错误",
    PF0029:"Информация о коробке неверна",
    //盲盒信息错误",
    PF0030:"Информация о слепой коробке неверна",
    //当天仅限开箱一次",
    PF0031:"В этот день можно открыть только одну коробку",
    //新注册用户仅限开箱一次",
    PF0032:"Новые зарегистрированные пользователи могут открыть только одну коробку",
    //玩家信息不存在",
    PF0033:"Информация об игроке не существует",
    //当前余额不足，请充值",
    PF0034:"Недостаточный баланс, пожалуйста, пополните счет",
    //缺少玩家信息",
    PF0035:"Отсутствует информация об игроке",
    //活动已结束",
    PF0036:"Мероприятие завершено",
    //报名时间已结束",
    PF0037:"Время регистрации истекло",
    //您已在该房间内，看看别的活动房间吧",
    PF0038:"Вы уже в этой комнате, посмотрите другие комнаты",
    //密码或口令有误，参加失败",
    PF0039:"Неверный пароль или пароль, участие не удалось",
    //未达到充值额度条件，参加失败",
    PF0040:"Не удалось принять участие, не достигнув условий пополнения",
    //口令错误或者充值金额未达标，参加失败",
    PF0041:"Неверный пароль или не достигнув условий пополнения, участие не удалось",
    //您不是该主播的下级或充值金额未达标，参加失败	",
    PF0042:"Вы не являетесь подчиненным этого хозяина или не достигнув условий пополнения, участие не удалось",
    //房间已开奖",
    PF0043:"Комната уже открыта",
    //开奖时间还未到",
    PF0044:"Время розыгрыша еще не наступило",
    //暂无抽奖玩家",
    PF0045:"Нет игроков в лотерее",
    //暂无奖品，无法开奖",
    PF0046:"Нет призов, не может быть открыт",
    //抽奖发生错误",
    PF0047:"Ошибка лотереи",
    //抽奖完成",
    PF0048:"Лотерея завершена",
    //结算完成",
    PF0049:"Расчет завершен",
    //请输入账号",
    PF0050:"Пожалуйста, введите учетную запись",
    //请输入密码",
    PF0051:"Пожалуйста, введите пароль",
    //账号或者密码有误",
    PF0052:"Неверный логин или пароль",
    //登录成功",
    PF0053:"Вход успешно",
    //请输入手机号码",
    PF0054:"Пожалуйста, введите номер мобильного телефона",
    //手机号格式不正确",
    PF0055:"Неверный формат номера мобильного телефона",
    //请输入验证码",
    PF0056:"Пожалуйста, введите код подтверждения",
    //请输入密码",
    PF0057:"Пожалуйста, введите пароль",
    //验证码错误",
    PF0058:"Неверный код подтверждения",
    //验证码已失效",
    PF0059:"Код подтверждения устарел",
    //新密码与旧密码相同",
    PF0060:"Новый пароль совпадает с старым паролем",
    //操作成功",
    PF0061:"Операция успешна",
    //账号不存在",
    PF0062:"Учетная запись не существует",
    //未中奖",
    PF0063:"Не выиграл",
    //缺少饰品信息",
    PF0064:"Отсутствует информация о украшениях",
    //缺少玩家信息",
    PF0065:"Отсутствует информация об игроке",
    //开奖信息有误",
    PF0066:"Неверная информация о лотерее",
    //饰品信息不存在",
    PF0067:"Информация о украшениях не существует",
    //账户余额不足",
    PF0068:"Недостаточный баланс",
    //操作失败",
    PF0069:"Операция не удалась",
    //恭喜中奖",
    PF0070:"Поздравляем с выигрышем",
    //不支持该玩法",
    PF0071:"Эта игра не поддерживается",
    //用户已存在",
    PF0072:"Пользователь уже существует",
    //手机号已注册",
    PF0073:"Номер мобильного телефона уже зарегистрирован",
    //邀请码错误",
    PF0074:"Неверный код приглашения",
    //验证码已失效",
    PF0075:"Код подтверждения устарел",
    //注册失败",
    PF0076:"Ошибка регистрации",
    //金额有误",
    PF0077:"Неверная сумма",
    //用户信息有误",
    PF0078:"Неверная информация о пользователе",
    //充值发生错误，请稍后再试	",
    PF0079:"Ошибка пополнения, попробуйте позже",
    //商户信息错误，请联系客服!",
    PF0080:"Ошибка информации о продавце, пожалуйста, свяжитесь с обслуживанием клиентов!",
    //购买商品出错，请稍后再试",
    PF0081:"Ошибка покупки товара, пожалуйста, попробуйте позже",
    //商品库存不足",
    PF0082:"Недостаточный товар",
    //购买成功",
    PF0083:"Покупка успешна",
    //非测试账号",
    PF0084:"Не тестовый аккаунт",
    //查无此用户",
    PF0085:"Нет такого пользователя",
    //请输入您的交易链接",
    PF0086:"Пожалуйста, введите свою ссылку на сделку",
    //不可绑定",
    PF0087:"Невозможно привязать",
    //绑定错误",
    PF0088:"Ошибка привязки",
    //您已绑定推荐人",
    PF0089:"Вы уже привязали реферала",
    //已超过3日有效期，无法绑定",
    PF0090:"Срок действия истек, невозможно привязать",
    //请输入您的昵称",
    PF0100:"Пожалуйста, введите свой ник",
    //文件不存在",
    PF0101:"Файл не существует",
    //错误，稍后再试",
    PF0102:"Ошибка, попробуйте позже",
    //该手机号不存在",
    PF0103:"Этот номер мобильного телефона не существует",
    //操作频繁",
    PF0104:"Частая операция",
    //皮肤取回中，请勿重复操作	",
    PF0105:"Возврат скина, пожалуйста, не повторяйте операцию",
    //皮肤信息错误",
    PF0106:"Ошибка информации о скине",
    //皮肤不存在",
    PF0107:"Скин не существует",
    //玩家不存在",
    PF0108:"Игрок не существует",
    //取回成功",
    PF0109:"Возврат успешен",
    //账户未绑定Steam",
    PF0110:"Учетная запись не привязана к Steam",
    //发送报价中,请关注您的取回助手",
    PF0111:"Отправка предложения, пожалуйста, обратите внимание на вашего помощника",
    //操作成功，请等待发货并关注您的取回助手",
    PF0112:"Операция успешна, пожалуйста, дождитесь доставки и обратите внимание на вашего помощника",
    //饰品兑换中...请稍后再操作",
    PF0113:"Обмен украшениями... Пожалуйста, повторите попытку позже",
    //缺少皮肤信息",
    PF0114:"Отсутствует информация о скине",
    //兑换失败",
    PF0115:"Ошибка обмена",
    //兑换成功",
    PF0116:"Обмен успешен",
    //邮箱格式错误",
    PF0117:"Неверный формат электронной почты",
    //服务错误，请联系管理员
    "0":"Ошибка обслуживания, пожалуйста, свяжитесь с администратором",

    }
  }