export default {
  common: {
    username: '用户名',
    password: '密码',
    save: '保存',
    edit: '编辑',
    update: '更新',
    delete: '删除',
    forever: '永久',
    expired: '过期',
    register: '注册',
    login: '登录',
    请先登录: '请先登录',
    手机注册: '手机注册',
    手机号码: '手机号码',
    设置密码: '设置密码',
    验证码: '验证码',
    获取验证码: '获取验证码',
    登录: '登录',
    手机号码或邮箱: '手机号码或邮箱',
    密码: '密码',
    记住登录: '记住登录',
    忘记密码: '忘记密码',
    还没账号: '还没账号',
    点击注册: '点击注册',
    重置密码: '重置密码',
    个人中心: '个人中心',
    我的账单: '我的账单',
    消息通知: '消息通知',
    退出登录: '退出登录',
    编辑: '编辑',
    选择当前页: '选择当前页',
    取消选择: '取消选择',
    兑换: '兑换',
    充值: '充值',
    关于我们: '关于我们',
    用户协议: '用户协议',
    隐私条款: '隐私条款',
    协议与条款: '协议与条款',
    常见问题: '常见问题',
    暂无数据: '暂无数据',
    服务不满意: '服务不满意，请联系客服',
    等待中: '等待中',
    进行中: '进行中',
    确定: '确定',
    全部: '全部',
    筛选: '筛选',
    取消全部: '取消全部',
    取消: '取消',
    保存: '保存',
    绑定: '绑定',
    修改密码: '修改密码',
    偏好设置: '偏好设置',
    邮箱注册: '邮箱注册',
    邮箱: '邮箱',
    邀请码: '邀请码',
    注册: '注册',

  },
  biz:{
    盲盒大厅:"盲盒大厅",
    盲盒对战: '盲盒对战',
    ROLL房:"ROLL房",
    幸运饰品:"幸运饰品",
    饰品商城:"饰品商城",
    活动推广:"活动推广",
    饰品背包:"饰品背包",
    加群有奖:"加群有奖",
    暂时没有红包哦:"暂时没有红包哦",
    已结束:"已结束",
    回合:"回合",
    查看:"查看",
    查看更多:"查看更多",
    时间:"时间",
    编号:"编号",
    价格从低到高:"价格从低到高",
    价格从高到低:"价格从高到低",
    充值流水:"充值流水",
    余额流水:"余额流水",
    日期:"日期",
    支付:"支付",
    充值方式:"充值方式",
    充值金额:"充值金额",
    充值结果:"充值结果",
    购买物品:"购买物品",
    类型:"类型",
    描述:"描述",
    数量:"数量",
    余额:"余额",
    包含以下皮肤:"包含以下皮肤",
    最近掉落:"最近掉落",
    挑战:"挑战",
    排行榜:"排行榜",
    对战之神:"对战之神",
    对战规则:"对战规则",
    对战记录:"对战记录",
    创建对战:"创建对战",
    个房间等待您加入:"个房间等待您加入",
    每局活动:"每局活动，玩家连续打开相同盲盒，奖品总价最高的玩家可获得全部奖品",
    我的对战记录:"我的对战记录",
    所有记录:"所有记录",
    玩家:"玩家",
    盲盒信息:"盲盒信息",
    状态:"状态",
    精彩回顾:"精彩回顾",
    对战结果:"对战结果",
    全部房间:"全部房间",
    我参与的:"我参与的",
    官方:"官方",
    主播:"主播",
    立即参与:"立即参与",
    等待玩家:"等待玩家",
    对战历史:"对战历史",
    参加对战编号:"参加对战编号",
    详情:"详情",
    打开:"打开",
    包含以下奖励:"包含以下奖励",
    胜利:"胜利",
    失败:"失败",
    库存:"库存",
    立即购买:"立即购买",
    奖池:"奖池",
    件数:"件数",
    人数:"人数",
    加入房间:"加入房间",
    开奖时间:"开奖时间",
    参与人数:"参与人数",
    房间简介:"房间简介",
    免费皮肤:"免费皮肤",
    房间编号:"房间编号",
    截止时间:"截止时间",
    中奖纪录:"中奖纪录",
    奖池奖品:"奖池奖品",
    价值:"价值",
    件:"件",
    剩余:"剩余",
    已送出:"已送出",
    共计:"共计",
    人:"人",
    最低金额:"最低金额",
    最高金额:"最高金额",
    按名称搜索:"按名称搜索",
    饰品:"饰品",
    取回助手:"取回助手",
    没有进行中的报价:"没有进行中的报价",
    全部隐藏:"全部隐藏",
    选择你心仪的商品:"选择你心仪的商品",
    您的可用金币数量为:"您的可用金币数量为",
    选择你要取回的饰品:"选择你要取回的饰品",
    点击取回后:"点击取回后，饰品稍后会在取回助手中，请点击右侧取回助手点击接受报价。12小时内累计拒绝报价或不收货行为超过 2 次，你的取回功能将会被系统禁用 24 小时。",
    邀请:"邀请",
    用户ID:"用户ID",
    上次登录:"上次登录",
    Steam交易链接:"Steam交易链接",
    输入您的交易链接:"输入您的交易链接",
    新用户3天内可以绑定上级:"新用户3天内可以绑定上级",
    账号管理:"账号管理",
    邮箱绑定:"邮箱绑定",
    开启声音:"开启声音",
    关闭声音:"关闭声音",
    //活动结果提醒（参与活动结束后结果会通过浏览器通知告知）
    活动结果提醒:"活动结果提醒（参与活动结束后结果会通过浏览器通知告知）",
    点击这里:"点击这里",
    点击看教程:"点击看教程",
    获取你的Steam交易链接:"获取你的Steam交易链接",
    打不开Steam怎么办:"打不开Steam怎么办",
    取回通知:"取回通知",
    系统通知:"系统通知",
    姓名:"姓名",
    地址:"地址",
    没有可以不填:"没有可以不填",
    本人已年满18周岁:"本人已年满18周岁",
    用户协议:"用户协议",
    隐私条款:"隐私条款",
    和:"和",
    我已经阅读并接受本站的:"我已经阅读并接受本站的",
    //已有账号?点击登录
    已有账号:"已有账号?点击登录",
    想起密码了:"想起密码了?",
    规则:"规则",
    关于对战:"关于对战",
    请输入对战场次编号:"请输入对战场次编号",
    验证对战:"验证对战",
    取回:"取回",
    取回中:"取回中",
    开箱规则:"开箱规则",
    盲盒开箱:"盲盒开箱",
    收入:"收入",
    支出:"支出",
    充值:"充值",
    皮肤兑换Z币:"皮肤兑换Z币",
    对战失败:"对战失败",
    对战存在多个平局赢家平分输家的钱:"对战存在多个平局赢家平分输家的钱",
    购买盲盒:"购买盲盒",
    加入对战房间:"加入对战房间",
    购买幸运饰品:"购买幸运饰品",
    商城购买饰品:"商城购买饰品",
    我的购物车:"我的购物车",
    崭新出厂:"崭新出厂",
    略有磨损:"略有磨损",
    久经沙场:"久经沙场",
    破损不堪:"破损不堪",
    战痕累累:"战痕累累",
    无涂装:"无涂装",
    放入背包:"放入背包",
    掉落概率:"掉落概率",
    跳过动画:"跳过动画",
    选择你期望的幸运值:"选择你期望的幸运值",
    玩法介绍和特别说明:"玩法介绍和特别说明",
    随机物品:"随机物品",
    成功:"成功",
    失败:"失败",
    试试运气:"试试运气",
    花费:"花费",
    你会有:"你会有",
    //的机会，获得一件珍稀的饰品
    的机会:"的机会，获得一件珍稀的饰品",
    未支付:"未支付",
    待支付:"待支付",
    支付成功:"支付成功",
    支付失败:"支付失败",
    支付确认中:"支付确认中",
    名字:"名字",
    姓氏:"姓氏",
    国家:"国家",
    不能未空:"不能未空",
    地区:"地区",
    城市:"城市",
    地址:"地址",
    邮编:"邮编",
    邮箱:"邮箱",
    电话:"电话",
    性别:"性别",
    身份证号:"身份证号",
    卡号:"卡号",
    有效年:"有效年",
    有效月:"有效月",
    立即充值:"立即充值",
    取回错误请联系客服:"取回错误,请联系客服",
    正在取回中稍后重试:"正在取回中,稍后重试",
    请选择箱子:"请选择箱子",
    盲盒数量已达上限:"盲盒数量已达上限",
    复制成功:"复制成功",
    输入口令领红包:"输入口令领红包",
    恭喜您获得:"恭喜您获得",

  },
  msg:{
    PF0001: "参数错误",
    PF0002: "请输入红包口令",
    PF0003: "红包信息不存在",
    PF0004: "口令有误",
    PF0005: "红包已抢过了",
    PF0006: "红包已过期",
    PF0007: "红包抢光了",
    PF0008: "红包信息错误",
    PF0009: "恭喜抢到红包",
    PF0010: "无数据",
    PF0011: "请选择盒子",
    PF0012: "创建成功",
    PF0013: "缺少对战id信息",
    PF0014: "缺少入战玩家信息",
    PF0015: "游戏房间信息不存在",
    PF0016: "您已经在该房间，不可与自己对战",
    PF0017: "房间人数已满",
    PF0018: "盲盒数据不存在",
    PF0019: "对战失败",
    PF0020: "加入对战失败",
    PF0021: "加入成功",
    PF0022: "对战已结束",
    PF0023: "奖品库存不足，开奖失败",
    PF0024: "本场开奖已完成",
    PF0025: "盲盒信息错误",
    PF0026: "玩家信息错误",
    PF0027: "参与失败，请充值",
    PF0028: "失败",
    PF0029: "盒子信息错误",
    PF0030: "盲盒信息错误",
    PF0031: "当天仅限开箱一次",
    PF0032: "新注册用户仅限开箱一次",
    PF0033: "玩家信息不存在",
    PF0034: "当前余额不足，请充值",
    PF0035: "缺少玩家信息",
    PF0036: "活动已结束",
    PF0037: "报名时间已结束",
    PF0038: "您已在该房间内，看看别的活动房间吧",
    PF0039: "密码或口令有误，参加失败",
    PF0040: "未达到充值额度条件，参加失败",
    PF0041: "口令错误或者充值金额未达标，参加失败",
    PF0042: "您不是该主播的下级或充值金额未达标，参加失败	",
    PF0043: "房间已开奖",
    PF0044: "开奖时间还未到",
    PF0045: "暂无抽奖玩家",
    PF0046: "暂无奖品，无法开奖",
    PF0047: "抽奖发生错误",
    PF0048: "抽奖完成",
    PF0049: "结算完成",
    PF0050: "请输入账号",
    PF0051: "请输入密码",
    PF0052: "账号或者密码有误",
    PF0053: "登录成功",
    PF0054: "请输入手机号码",
    PF0055: "手机号格式不正确",
    PF0056: "请输入验证码",
    PF0057: "请输入密码",
    PF0058: "验证码错误",
    PF0059: "验证码已失效",
    PF0060: "新密码与旧密码相同",
    PF0061: "操作成功",
    PF0062: "账号不存在",
    PF0063: "未中奖",
    PF0064: "缺少饰品信息",
    PF0065: "缺少玩家信息",
    PF0066: "开奖信息有误",
    PF0067: "饰品信息不存在",
    PF0068: "账户余额不足",
    PF0069: "操作失败",
    PF0070: "恭喜中奖",
    PF0071: "不支持该玩法",
    PF0072: "用户已存在",
    PF0073: "手机号已注册",
    PF0074: "邀请码错误",
    PF0075: "验证码已失效",
    PF0076: "注册失败",
    PF0077: "金额有误",
    PF0078: "用户信息有误",
    PF0079: "充值发生错误，请稍后再试	",
    PF0080: "商户信息错误，请联系客服!",
    PF0081: "购买商品出错，请稍后再试",
    PF0082: "商品库存不足",
    PF0083: "购买成功",
    PF0084: "非测试账号",
    PF0085: "查无此用户",
    PF0086: "请输入您的交易链接",
    PF0087: "不可绑定",
    PF0088: "绑定错误",
    PF0089: "您已绑定推荐人",
    PF0090: "已超过3日有效期，无法绑定",
    PF0100: "请输入您的昵称",
    PF0101: "文件不存在",
    PF0102: "错误，稍后再试",
    PF0103: "该手机号不存在",
    PF0104: "操作频繁",
    PF0105: "皮肤取回中，请勿重复操作	",
    PF0106: "皮肤信息错误",
    PF0107: "皮肤不存在",
    PF0108: "玩家不存在",
    PF0109: "取回成功",
    PF0110: "账户未绑定Steam",
    PF0111: "发送报价中,请关注您的取回助手",
    PF0112: "操作成功，请等待发货并关注您的取回助手",
    PF0113: "饰品兑换中...请稍后再操作",
    PF0114: "缺少皮肤信息",
    PF0115: "兑换失败",
    PF0116: "兑换成功",
    PF0117: "邮箱格式错误",
    "0":"服务错误，请联系管理员"
  }
}

