<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">Privacy Policy</div>
      <pre class="content">
        788skins may change this Privacy Policy from time to time and the change will take effect once the revised Privacy Policy is available on this Application. The “last updated” date is at the bottom of this Policy. Therefore, please refer to this Privacy Policy each time you submit your personal information.

 

Data Controller and Owner

788skins.com

Owner contact email: privacy@788skins.com

 

Types of Data collected

Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookies and Usage Data.

Other Personal Data collected may be described in other sections of this privacy policy or by dedicated explanation text contextually with the Data collection.

The Personal Data may be freely provided by the User, or collected automatically when using this Application.

Any use of Cookies - or of other tracking tools - by this Application or by the owners of third party services used by this Application, unless stated otherwise, serves to identify Users and remember their preferences, for the sole purpose of providing the service required by the User.

Failure to provide certain Personal Data may make it impossible for this Application to provide its services.

Users are responsible for any Personal Data of third parties obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.

 

Mode and place of processing the Data

 

Methods of processing

The Data Controller processes the Data of Users in a proper manner and shall take appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.

The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Data Controller, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of the site (administration, sales, marketing, legal, system administration) or external parties (such as third party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Data Controller at any time.

 

 

 

 

Place

The Data is processed at the Data Controller's operating offices and in any other places where the parties involved with the processing are located. For further information, please contact the Data Controller.

 

Retention time

The Data is kept for the time necessary to provide the service requested by the User, or stated by the purposes outlined in this document, and the User can always request that the Data Controller suspend or remove the data. In no event, the Data will be kept longer than 5 years after the latest activity of the User on the site.

 

How Do We Protect Your Information?

We are committed to protecting your privacy and we implement various security measures in relation to our processing and transfer of personal data. However, the nature of the Internet is such that the data may in some circumstances flow over networks without full security measures and could be accessible to unauthorized persons.

 

 

How Do We Use Your Information?

We will use your personal information for our legitimate interests to respond to your enquiries, to send information to you, to fulfil any contractual obligation to you, for research purposes, to send marketing information to you regarding our goods, services and related opportunities, and other reasonable uses by virtue of your affirmative consent.

 

If you purchase goods or services from us we may send you further information that is related to your purchase, by post, push notification or e-mail.

 

We would like to send you marketing information, by push notifications and e-mail. 

 

If you do not wish to receive any of the above information or be contacted by us in accordance with the above, please select the “Unsubscribe” button on our email, or write to the 788skins at the address below.

 

If you submit your views to us via our contact form we may circulate them internally for training and management purposes.

 

We review the Personal Information we collect periodically to determine its accuracy and whether it can be deleted.

 

We may aggregate the information you send to us with other data (so that you cannot be identified from that data) and use that aggregated data for administrative purposes.

 

Push notifications

This Application may send push notifications to the User.

 

Analytics

The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.

 

Google Analytics (Google Inc.)

Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.

Google may use the Data collected to contextualize and personalize the ads of its own advertising network.

 

Personal Data collected: Cookies and Usage Data.

 

Place of processing: US – Privacy Policy – Opt Out.

 

Google AdWords conversion tracking (Google Inc.)

Google AdWords conversion tracking is an analytics service provided by Google Inc. that connects data from the Google AdWords advertising network with actions performed on this Application.

 

Personal Data collected: Cookies and Usage Data.

 

Place of processing: US – Privacy Policy.

 

Twitter Ads conversion tracking (Twitter, Inc.)

Twitter Ads conversion tracking is an analytics service provided by Twitter, Inc. that connects data from the Twitter advertising network with actions performed on this Application.

 

Personal Data collected: Cookies and Usage Data.

 

Place of processing: US – Privacy Policy.

 

The rights of Users

Users may exercise certain rights regarding their Data processed by the Owner.

 

In particular, Users have the right to do the following:

 

Withdraw their consent at any time. Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.
Object to processing of their Data. Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.
Access their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.
Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.
Restrict the processing of their Data. Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.
Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.
Receive their Data and have it transferred to another controller. Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.
Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.
 

 

Definitions and legal references

Personal Data (or Data)

Any information that directly, indirectly, or in connection with other information allows for the identification or identifiability of a natural person. Such information may include name, address, passport or ID data, personal identification number, and any other data which collectively may allow to identify a person.

 

Usage Data

Information collected automatically through this Site, which can include: the IP addresses or domain names of the computers utilized by the Users who use this Site, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Site) and the details about the path followed within the Site with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.

 

User

The individual using this Site who, unless otherwise specified, coincides with the Data Subject.

 

Data Subject

The natural person to whom the Personal Data refers.

 

Data Processor (or Data Supervisor)

The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.

 

 

 

Data Controller (or Owner)

The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Site. The Data Controller, unless otherwise specified, is the Owner of this Site.

 

This Application

The means by which the Personal Data of the User is collected and processed.

 

Service

The service provided by this Site as described in the relative terms (if available) and on this Site.

 

European Union (or EU)

Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.

 

Cookies

Small piece of data stored in the User's device.
      </pre>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
pre { // 兼容多个浏览器
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    *word-wrap: break-word;
    *white-space : normal ;
    font-size: 18px;
    color: #848492;
    padding: 16px 0;
}
.doubt {
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    font-size: 18px;
    color: #848492;
    padding-bottom: 88px;
    h3 {
      color: #ddd;
      font-weight: normal;
      margin-top: 42px;
    }
  }
}
</style>