<template>
  <div class="rule">
    <div class="rule-warp">
      <div class="roomleft-num">
        <h5 @click="goLucky">{{ $t("biz.盲盒对战") }}</h5>
        >
        <h6>{{ $t("biz.规则") }}</h6>
      </div>

      <div class="rule-text">
        <div class="text-title">{{ $t('biz.关于对战')}}</div>
        <p>
          1
          When creating a battle, you can choose the number of people to fight (1V1, 3-player and 4-player), and you can choose at most for a random battle
          6 blind boxes. Once a room is created, it cannot be canceled.
        </p>
        <p>
          2
          The battle participation fee is the sum of the price of the blind box opened in this battle. After paying the fee, it is deemed to have joined the room. If you leave the room halfway, the blind box or participation fee will not be refunded.
        </p>
        <p>
          3
          When there are multiple winners in a battle and the rewards are shared equally, the accessories not obtained by the person will be exchanged for coins and distributed to the winner's account balance.
        </p>
        <p>
          4
          The result of each battle is affected by many factors (creation time, user ID, user location and start time, etc.), and it is a completely random result. Any blind box reward can verify fairness.
        </p>
      //</div>

      <div class="rule-input">
        <div class="input-title">{{$t('biz.请输入对战场次编号')}}</div>
       <div class="input">
          <input type="text" />
          <span>{{$t('biz.验证对战')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.rule {
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;

  .rule-warp {
    padding: 16px;
  }
  .rule-text {
    margin-top: 20px;
    .text-title {
      padding: 10px 0;
      color: #c3c3e2;
      font-size: 16px;
    }
    p {
      font-size: 14px;
      color: #848492;
      padding: 8px 0;
    }
  }
  .rule-input {
    display: table;
    margin-top: 30px;
    padding: 16px;
    background-color: #2b2c37;
    border-radius: 5px;
    .input-title {
      font-size: 16px;
      color: #c3c3e2;
    }
    .input {
      padding: 16px 0;
      input {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 5px;
        width: 400px;
        color: #c3c3e2;
        font-size: 12px;
        background-color: #24252f;
        outline: none;
        box-sizing: border-box;
        border: none;
      }
      span {
        display: inline-table;
        height: 40px;
        margin-left: 20px;
        line-height: 40px;
        padding: 0 20px;
        background-color: #e9b10e;
        border-radius: 5px;
        font-weight: 600;
        color: #1a1c24;
        font-size: 15px;
      }
      span:hover {
        cursor: pointer;
        background-color: #f5c432;
      }
    }
  }

  .roomleft-num {
    display: flex;
    align-items: center;
    color: #848492;
    font-size: 16px;

    h5 {
      font-size: 16px;
      font-weight: 200;
    }
    h5:hover {
      cursor: pointer;
      color: #e9b10e;
      text-decoration: underline;
    }
    h6 {
      font-weight: 200;
      color: #c3c3e2;
      font-size: 16px;
    }
  }
}
</style>