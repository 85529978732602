<template>
  <div class="rule">
    <div class="rule-warp">
      <div class="roomleft-num">
        <h5 @click="goopenrule">{{$t('biz.盲盒开箱')}}</h5>
        >
        <h6>{{$t('biz.规则')}}</h6>
      </div>

      <div class="rule-text">
        <div class="text-title">{{$t('biz.开箱规则')}}</div>
        <p>
          1,
          The gold coins given to new users for registration are limited to opening the box for new users and opening the box for cute new users. Other games need to be recharged to open the authority, and there is no limit to the withdrawal.
        </p>
        <p>
          2,
          There are two types of box opening probability display, one is fixed probability and the other is dynamic probability<br>
          Fixed probability: the probability value is fixed and will not change with opening the box<br>
          Dynamic probability: The value of dynamic probability changes with the number of box openings and lottery results. If you start at the right time, you will have unexpected gains.
        </p>
        <p>
          3.
          The probabilities of new user unboxing, cute new unpacking, and small test are dynamic probabilities, and the probabilities of the rest of the boxes are fixed probabilities.
        </p>
      //</div>

    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
    goopenrule(){

    }
  },
};
</script>

<style lang="less" scoped>
.rule {
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;

  .rule-warp {
    padding: 16px;
  }
  .rule-text {
    margin-top: 20px;
    .text-title {
      padding: 10px 0;
      color: #c3c3e2;
      font-size: 16px;
    }
    p {
      font-size: 14px;
      color: #848492;
      padding: 8px 0;
    }
  }
  .rule-input {
    display: table;
    margin-top: 30px;
    padding: 16px;
    background-color: #2b2c37;
    border-radius: 5px;
    .input-title {
      font-size: 16px;
      color: #c3c3e2;
    }
    .input {
      padding: 16px 0;
      input {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 5px;
        width: 400px;
        color: #c3c3e2;
        font-size: 12px;
        background-color: #24252f;
        outline: none;
        box-sizing: border-box;
        border: none;
      }
      span {
        display: inline-table;
        height: 40px;
        margin-left: 20px;
        line-height: 40px;
        padding: 0 20px;
        background-color: #e9b10e;
        border-radius: 5px;
        font-weight: 600;
        color: #1a1c24;
        font-size: 15px;
      }
      span:hover {
        cursor: pointer;
        background-color: #f5c432;
      }
    }
  }

  .roomleft-num {
    display: flex;
    align-items: center;
    color: #848492;
    font-size: 16px;

    h5 {
      font-size: 16px;
      font-weight: 200;
    }
    h5:hover {
      cursor: pointer;
      color: #e9b10e;
      text-decoration: underline;
    }
    h6 {
      font-weight: 200;
      color: #c3c3e2;
      font-size: 16px;
    }
  }
}
</style>